.custom-textfield .MuiInputBase-input {
  color: black !important; /* Change the color */
  width: 100% !important; /* Ensure the text field takes full width */
  /* padding: 3%; */
  font-size: 1.1rem;
  /* background-color: white; */
  border-radius: 7.5px !important;
}

.custom-textfield .css-1q964xs-MuiFormLabel-root-MuiInputLabel-root {
  color: #1976d2;
  font-weight: bold;
}
