.e-toolbar,
.e-toolbar-items {
  background-color: rgb(226, 226, 226) !important; /* Change background color */
  color: white !important; /* Change text color */
}

.e-toolbar .e-toolbar-item .e-tbar-btn {
  background: transparent;
}

.input-container {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #666;
}

.e-input {
  padding-left: 30px; /* Adjust padding to make room for the icon */
  width: 100%;
  box-sizing: border-box;
}
