/* Sidebar container (expanded) */
.sidenav {
  width: 14%; /* Increased width for better visibility */
  background-color: #003b5c; /* Darker blue for modern look */
  color: #ffffff;
  height: 100vh;
  overflow-y: auto;
  transition: all 0.3s ease;
  padding: 15px 10px;
  position: fixed;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Sidebar container (collapsed) */
.sidenavClosed {
  width: 3%; /* Compact width */
  background-color: #003b5c;
  color: #ffffff;
  height: 100vh;
  overflow-y: auto;
  transition: all 0.3s ease;
  padding: 15px 10px;
  position: fixed;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

/* Sidebar logo */
.sidenav img {
  width: 100%;
  border-radius: 8px; /* Rounded corners for logo */
  margin-bottom: 20px;
}

/* Toggle button */
.menuBtn {
  background-color: #004b74; /* Slightly lighter blue */
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 50%; /* Circular button */
  /* position: absolute; */
  /* top: 20px; */
  z-index: 1100;
  transition: background-color 0.3s ease;
  /* margin-top: 8%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuBtn:hover {
  background-color: #00aaff; /* Bright blue hover effect */
}

/* Sidebar items */
.sideitem {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px; /* Smooth rounded edges */
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  font-weight: 500; /* Medium font weight for better readability */
}

.sideitem:hover {
  background-color: #005d85; /* Highlighted hover background */
  color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Sidebar icon */
.sideitem svg {
  font-size: 24px;
  /* margin-right: 15px; */
  transition: transform 0.3s ease;
}

/* Icon animation on hover */
.sideitem:hover svg {
  transform: scale(1.1); /* Slight zoom effect */
}

/* Collapsed sidebar: hide text */
.sidenavClosed .sideitem span {
  display: none; /* Hide text in collapsed state */
}

.sidenavClosed .sideitem {
  justify-content: center; /* Center icons in collapsed state */
  padding: 12px 0;
}

/* Section divider */
.MuiDivider-root {
  border-color: #4c6275; /* Softer border color */
  margin: 20px 0;
}

/* Sidebar text */
.linkText {
  font-size: 1.2rem;
  color: #d0e4f1; /* Subtle, lighter text color */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkTextClosed {
  display: none; /* Hide text in collapsed state */
}

/* Scrollbar customization */
.sidenav::-webkit-scrollbar {
  width: 6px;
}

.sidenav::-webkit-scrollbar-track {
  background: #003b5c;
}

.sidenav::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

.sidenav::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .sidenav {
    width: 200px;
  }
  .sidenavClosed {
    width: 60px;
  }
}

@media screen and (max-width: 480px) {
  .sidenav {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidenavClosed {
    width: 100%;
  }
}
