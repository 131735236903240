.container {
  position: fixed; /* Make the container fixed to the viewport */
  top: 50%; /* Position 50% from the top */
  left: 50%; /* Position 50% from the left */
  transform: translate(-50%, -50%); /* Center the container */
  background-color: #ebe8e8;
  width: 50%;
  padding: 2%;
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add some shadow for a polished look */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.buttonSave {
  background-color: #1976d2;
  border: none;
  width: 15%;
  color: white;
  margin-right: 2%;
  border-radius: 7.5px;
  padding: 0.5%;
}

.buttonClose {
  background-color: gray;
  border: none;
  width: 15%;
  color: white;
  border-radius: 7.5px;
  padding: 0.5%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Below the dialog but above the rest of the content */
}
