.control-section .dual-list-wrapper {
  width: 80%;
  margin: 0 auto 10px;
  display: flex;
}
.dual-list-groupa,
.dual-list-groupb {
  width: 50%;
}
.dual-list-groupb .e-listbox-wrapper,
.dual-list-groupb h4 {
  width: 87%;
  margin-left: 15px;
}
@media screen and (max-width: 590px) {
  .control-section .dual-list-wrapper {
    width: 100%;
  }
  .dual-list-groupa {
    width: 56%;
  }
  .dual-list-groupb {
    width: 44%;
  }
}
@media screen and (max-width: 400px) {
  .dual-list-groupa {
    width: 59%;
  }
  .dual-list-groupb {
    width: 41%;
  }
}

#loader {
  color: #008cff;
  height: 40px;
  left: 45%;
  position: absolute;
  top: 45%;
  width: 30%;
}

.e-listbox-wrapper {
  /* margin: auto; */
  max-width: 400px;
  box-sizing: border-box;
}

#container {
  box-sizing: border-box;
}

#container .e-listbox-wrapper:not(.e-list-template) .e-list-item {
  padding: 0;
  position: unset;
  cursor: pointer;
  height: 76px;
  line-height: normal;
}

.list-wrapper {
  height: inherit;
  position: relative;
  padding: 14px 12px 14px 78px;
}

.list-wrapper .text,
.list-wrapper .description {
  display: block;
  margin: 0;
  padding-bottom: 3px;
  white-space: normal;
}

.list-wrapper .description {
  font-size: 12px;
  font-weight: 500;
}

#container .e-listbox-wrapper .list-wrapper .text {
  font-weight: bold;
  font-size: 13px;
}

.list-wrapper .e-avatar {
  position: absolute;
  left: 5px;
  background-color: transparent;
  font-size: 22px;
  top: calc(50% - 33px);
}

.image {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
}
